import React from "react";
//import styles from "./training.module.css";
import TrainingBanner from "./trainingBanner";
import infoBg from "../../assets/img/125637.jpg";
import Especifica from "./especifica";

const InfoEspecifica = () => {
  return (
    <div>
      <TrainingBanner backgroundImage={infoBg} title="Career Descriptions" />
      {/* Resto del contenido de la sección */}

      <div>
        <Especifica />
      </div>
    </div>
  );
};

export default InfoEspecifica;
