import React, { useState } from "react";
import GlobalData from "./globalData"; // Datos globales
import Modal from "./modal";
import "./global.css";

const brands = {
  QuickLending: { name: "Quick Lending", imageUrl: "/img/ql_logo.png" },
  QuickLendingDeluxe: {
    name: "Quick Lending Deluxe",
    imageUrl: "/img/QLDeluxe.png",
  },
  VIPHomeLoans: { name: "VIP Home Loans", imageUrl: "/img/viphomeloans.png" },
  AtlasSolutions: {
    name: "Atlas Solutions",
    imageUrl: "/img/atlasSolutions.png",
  },
  MyVirtualAssistant: {
    name: "My Virtual Assistant",
    imageUrl: "/img/MVA.png",
  },
  MyProcessingTeam: { name: "My Processing Team", imageUrl: "/img/mpt.png" },
  FlowersofCentralFlorida: {
    name: "Flowers of Central Florida",
    imageUrl: "/img/Flowers.png",
  },
  JPCapital: { name: "JP Capital", imageUrl: "/img/JPC-.png" },
  ConstructionDepot: {
    name: "Construction Depot",
    imageUrl: "/img/Construction_Depot-1.png",
  },
  TheTaxAdvisor: {
    name: "The Tax Advisor",
    imageUrl: "/img/TheTaxAdvisor.png",
  },
};

const Global = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [filter, setFilter] = useState("all");
  const [brandFilter, setBrandFilter] = useState("all");
  const [searchTerm, setSearchTerm] = useState("");

  const openFile = (file) => {
    setSelectedFile(file);
  };

  const closeModal = () => {
    setSelectedFile(null);
  };

  const getEmojiForType = (type) => {
    switch (type) {
      case "document":
        return "📄";
      case "pdf":
        return "📕";
      case "video":
        return "📹";
      case "photo":
        return "📸";
      case "youtube":
        return "🎥";
      default:
        return "📁";
    }
  };

  const filteredData = GlobalData.filter((file) => {
    const matchesType = filter === "all" || file.type === filter;
    const matchesBrand = brandFilter === "all" || file.brand === brandFilter;
    const matchesSearch = file.name
      .toLowerCase()
      .includes(searchTerm.toLowerCase());
    return matchesType && matchesBrand && matchesSearch;
  });

  return (
    <div className="global-container">
      <div className="filter-container">
        <select onChange={(e) => setFilter(e.target.value)} value={filter}>
          <option value="all">All Types</option>
          <option value="pdf">PDF</option>
          <option value="video">Video</option>
          <option value="photo">Photos</option>
          <option value="document">Document</option>
          <option value="youtube">YouTube Videos</option>
        </select>

        <select
          onChange={(e) => setBrandFilter(e.target.value)}
          value={brandFilter}
        >
          <option value="all">All Brands</option>
          {Object.entries(brands).map(([key, brand]) => (
            <option key={key} value={key}>
              {brand.name}
            </option>
          ))}
        </select>

        <input
          type="text"
          placeholder="Search by name"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>

      <div className="card-grid">
        {filteredData.map((file) => (
          <div key={file.id} className="card" onClick={() => openFile(file)}>
            <div className="card-emoji">{getEmojiForType(file.type)}</div>
            <div className="card-name">{file.name}</div>
            {file.brand && (
              <div className="card-footer">
                <img
                  src={brands[file.brand]?.imageUrl}
                  alt={brands[file.brand]?.name}
                  className="brand-logo"
                />
              </div>
            )}
          </div>
        ))}
      </div>

      {selectedFile && (
        <Modal onClose={closeModal}>
          {selectedFile.type === "video" ? (
            <video controls src={selectedFile.url} className="modal-content" />
          ) : selectedFile.type === "photo" ? (
            <img
              src={selectedFile.url}
              alt={selectedFile.name}
              className="modal-content"
            />
          ) : selectedFile.type === "youtube" ? (
            <iframe
              src={selectedFile.url}
              title={selectedFile.name}
              className="modal-content"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          ) : (
            <embed
              src={selectedFile.url}
              type="application/pdf"
              className="modal-content"
            />
          )}
        </Modal>
      )}
    </div>
  );
};

export default Global;
