const especificaData = [
  {
    category: "Dirección General y Coordinación Estratégica",
    roles: [
      {
        role: "CEO",
        details: [
          {
            id: 1,
            name: "ABC del CEO",
            type: "document",
            url: "path_to_description.pdf",
          },
        ],
      },
      {
        role: "Operations Manager",
        details: [
          {
            id: 1,
            name: "ABC del Operations Manager",
            type: "document",
            url: "path_to_description.pdf",
          },
        ],
      },
      {
        role: "Lead Coordinator",
        details: [
          {
            id: 1,
            name: "ABC del Lead Coordinator",
            type: "document",
            url: "path_to_description.pdf",
          },
        ],
      },
    ],
  },
  {
    category: "Departamento Operativos y Administrativos",
    roles: [
      {
        role: "Legal y Taxes",
        details: [
          {
            id: 4,
            name: "ABC del Legal y Taxes",
            type: "pdf",
            url: "path_to_tools.pdf",
          },
        ],
      },
      {
        role: "Administración",
        details: [
          {
            id: 4,
            name: "ABC del Administración",
            type: "pdf",
            url: "path_to_tools.pdf",
          },
        ],
      },
      {
        role: "Processing",
        details: [
          {
            id: 4,
            name: "ABC del Processing",
            type: "pdf",
            url: "path_to_tools.pdf",
          },
        ],
      },
    ],
  },
  {
    category: "Equipos Comerciales y Relacionales",
    roles: [
      {
        role: "Account Manager",
        details: [
          {
            id: 4,
            name: "ABC del Account Manager",
            type: "pdf",
            url: "https://docs.google.com/document/d/1S6rNFmVRMbFzfy-42w1Pl8blFovfVt5pSPKBrlUu2r0/edit?usp=sharing",
          },
        ],
      },
      {
        role: "Relationship Manager (RRMM)",
        details: [
          {
            id: 4,
            name: "ABC del Relationship Manager (RRMM)",
            type: "pdf",
            url: "path_to_tools.pdf",
          },
        ],
      },
    ],
  },
  {
    category: "Construccion y proyectos",
    roles: [
      {
        role: "Construction Account Manager",
        details: [
          {
            id: 4,
            name: "ABC del Construction Account Manager",
            type: "pdf",
            url: "path_to_tools.pdf",
          },
        ],
      },
      {
        role: "Contratistas Aliados",
        details: [
          {
            id: 4,
            name: "ABC del Contratistas Aliados",
            type: "pdf",
            url: "path_to_tools.pdf",
          },
        ],
      },
    ],
  },
  {
    category: "Equipo de atención al cliente: Call Center",
    roles: [
      {
        role: "Call center para Leads",
        details: [
          {
            id: 4,
            name: "ABC del Call center para Leads",
            type: "pdf",
            url: "path_to_tools.pdf",
          },
        ],
      },
      {
        role: "Call center servicio a cliente",
        details: [
          {
            id: 4,
            name: "ABC del Call center servicio a cliente",
            type: "pdf",
            url: "path_to_tools.pdf",
          },
        ],
      },
    ],
  },
  {
    category: "Media & Marketing",
    roles: [
      {
        role: "Community Manager",
        details: [
          {
            id: 4,
            name: "ABC del Community Manager",
            type: "pdf",
            url: "path_to_tools.pdf",
          },
        ],
      },
      {
        role: "Desarrollador Web",
        details: [
          {
            id: 4,
            name: "ABC del Desarrollador Web",
            type: "pdf",
            url: "path_to_tools.pdf",
          },
        ],
      },
      {
        role: "Diseñador Gráfico",
        details: [
          {
            id: 4,
            name: "ABC del Diseñador Gráfico",
            type: "pdf",
            url: "path_to_tools.pdf",
          },
        ],
      },
      {
        role: "Editor de Video",
        details: [
          {
            id: 4,
            name: "ABC del Editor de Video",
            type: "pdf",
            url: "path_to_tools.pdf",
          },
        ],
      },
      {
        role: "Marketing Manager",
        details: [
          {
            id: 4,
            name: "ABC del Marketing Manager",
            type: "pdf",
            url: "path_to_tools.pdf",
          },
        ],
      },
    ],
  },
  {
    category: "Competencias Avanzadas y Especializadas",
    roles: [
      {
        role: "Estrategias de Marketing y Ventas para Account Managers",
        details: [
          {
            id: 4,
            name: "Herramientas Esenciales",
            type: "pdf",
            url: "path_to_tools.pdf",
          },
        ],
      },
      {
        role: "Entrenamiento en Innovación y Adaptación al Cambio",
        details: [
          {
            id: 4,
            name: "Herramientas Esenciales",
            type: "pdf",
            url: "path_to_tools.pdf",
          },
        ],
      },
      {
        role: "Entrenamiento en Recursos Web como CRM",
        details: [
          {
            id: 4,
            name: "Herramientas Esenciales",
            type: "pdf",
            url: "path_to_tools.pdf",
          },
        ],
      },
      {
        role: "Capacitación en Productos Especializados y Non-QM",
        details: [
          {
            id: 4,
            name: "Herramientas Esenciales",
            type: "pdf",
            url: "path_to_tools.pdf",
          },
        ],
      },
    ],
  },
];

export default especificaData;
