import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyAnzbDnJG4NnMZRMqgUQUEqsZp1akhrxUo",
  authDomain: "atlasinvestments-58791.firebaseapp.com",
  projectId: "atlasinvestments-58791",
  storageBucket: "atlasinvestments-58791.appspot.com",
  messagingSenderId: "464318862378",
  appId: "1:464318862378:web:0546bc9126952e71075f53",
  measurementId: "G-MTBVCM3WFN",
};

// Inicializar Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

// Exportar solo los servicios necesarios
export { auth, db, storage };
