import React from "react";
//import styles from "./training.module.css";
import TrainingBanner from "./trainingBanner";
import Biblioteca from "./biblioteca";
import infoBg from "../../assets/img/112092.jpg";

const InfoBiblioteca = () => {
  return (
    <div>
      <TrainingBanner backgroundImage={infoBg} title="Information Library" />
      {/* Resto del contenido de la sección */}

      <div>
        <Biblioteca />
      </div>
    </div>
  );
};

export default InfoBiblioteca;
