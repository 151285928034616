// Training.js
import React from "react";
//import styles from "./training.module.css";
import TrainingBanner from "./trainingBanner";
import infoBg from "../../assets/img/23211.jpg";
import Global from "./global";

const InfoGlobal = () => {
  return (
    <div>
      <TrainingBanner backgroundImage={infoBg} title="Who We Are" />
      {/* Resto del contenido de la sección */}
      <div>
        <Global />
      </div>
    </div>
  );
};

export default InfoGlobal;
