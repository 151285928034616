import React, { useState } from "react";
import libraryData from "./libraryData";
import Modal from "./modal";
import "./biblioteca.css";

const Biblioteca = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [filter, setFilter] = useState("all");
  const [searchTerm, setSearchTerm] = useState("");

  const openFile = (file) => {
    setSelectedFile(file);
  };

  const closeModal = () => {
    setSelectedFile(null);
  };

  const getEmojiForType = (type) => {
    switch (type) {
      case "document":
        return "📄";
      case "pdf":
        return "📕";
      case "video":
        return "📹";
      case "photo":
        return "📸";
      case "youtube":
        return "🎥";
      default:
        return "📁";
    }
  };

  const filteredData = libraryData.filter((file) => {
    const matchesType = filter === "all" || file.type === filter;
    const matchesSearch = file.name
      .toLowerCase()
      .includes(searchTerm.toLowerCase());
    return matchesType && matchesSearch;
  });

  return (
    <div className="biblioteca-container">
      <div className="filter-container">
        <select onChange={(e) => setFilter(e.target.value)} value={filter}>
          <option value="all">All</option>
          <option value="pdf">PDF</option>
          <option value="video">Video</option>
          <option value="photo">Photos</option>
          <option value="document">Document</option>
          <option value="youtube">YouTube Videos</option>
        </select>
        <input
          type="text"
          placeholder="Search by name"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>

      <ul className="file-list">
        {filteredData.map((file) => (
          <li
            key={file.id}
            onClick={() => openFile(file)}
            className="file-item"
          >
            {file.name} -{" "}
            <span className="file-type">{getEmojiForType(file.type)}</span>
          </li>
        ))}
      </ul>

      {selectedFile && (
        <Modal onClose={closeModal}>
          {selectedFile.type === "video" ? (
            <video controls src={selectedFile.url} className="modal-content" />
          ) : selectedFile.type === "photo" ? (
            <img
              src={selectedFile.url}
              alt={selectedFile.name}
              className="modal-content"
            />
          ) : selectedFile.type === "youtube" ? (
            <iframe
              src={selectedFile.url}
              title={selectedFile.name}
              className="modal-content"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          ) : (
            <embed
              src={selectedFile.url}
              type="application/pdf"
              className="modal-content"
            />
          )}
        </Modal>
      )}
    </div>
  );
};

export default Biblioteca;
