import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import { auth } from "./firebase";
import { onAuthStateChanged } from "firebase/auth";
import Login from "./components/auth/Login";
import UserList from "./components/users/UserList";
import UserProfile from "./components/users/UserProfile";
import Training from "./components/trainings/trainings";
import Header from "./components/layout/Header"; // Importación del Header
import Verify from "./components/auth/Verify";
import Register from "./components/auth/Register";
import { Toaster } from "react-hot-toast";

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(null); // Estado inicial como null

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setIsAuthenticated(true); // Usuario autenticado
        localStorage.setItem("isAuthenticated", "true");
      } else {
        setIsAuthenticated(false); // Usuario no autenticado
        localStorage.removeItem("isAuthenticated");
      }
    });

    return () => unsubscribe(); // Limpia el listener al desmontar el componente
  }, []);

  if (isAuthenticated === null) {
    return <div>Loading...</div>; // Mostrar carga mientras se verifica la autenticación
  }

  return (
    <Router>
      <Toaster position="top-center" reverseOrder={false} />
      <AppRoutes isAuthenticated={isAuthenticated} />
    </Router>
  );
};

const AppRoutes = ({ isAuthenticated }) => {
  const location = useLocation();

  return (
    <>
      {/* Mostrar el Header solo si el usuario está autenticado */}
      {isAuthenticated &&
        !["/login", "/register", "/verify"].includes(location.pathname) && (
          <Header />
        )}

      <Routes>
        {/* Rutas públicas */}
        <Route path="/login" element={<Login />} />
        <Route path="/verify" element={<Verify />} />
        <Route path="/register" element={<Register />} />

        {/* Rutas protegidas */}
        {isAuthenticated ? (
          <>
            <Route path="/users" element={<UserList />} />
            <Route path="/profile/:id" element={<UserProfile />} />
            <Route path="/trainings" element={<Training />} />
            <Route path="*" element={<Navigate to="/users" />} />
          </>
        ) : (
          <Route path="*" element={<Navigate to="/login" />} />
        )}
      </Routes>
    </>
  );
};

export default App;
