/// libraryData.js

const libraryData = [
  {
    id: 1,
    name: "Estructura General",
    type: "document",
    url: "https://docs.google.com/document/d/1rOuKM54p5rkzyVWe3X-jxW4RaKuZwjiY/edit#heading=h.gjdgxs",
    brand: "QuickLending",
  },
  {
    id: 2,
    name: "Quienes somos",
    type: "document",
    url: "https://docs.google.com/document/d/1G8eiz3ityvZ78W3VrZyF1_7mmhrMNsy5/edit#heading=h.gjdgxs",
    brand: "QuickLending",
  },
  {
    id: 3,
    name: "Cultura de trabajo y dia a dia",
    type: "document",
    url: "https://docs.google.com/document/d/1PeAqAqmK_91nduMaVFJPwCJp7LWzS3q6/edit#heading=h.gjdgxs",
    brand: "QuickLending",
  },
  {
    id: 4,
    name: "Visión general de la industria hipotecario",
    type: "document",
    url: "https://docs.google.com/document/d/1lo_UThHzeJWiHMtdx9m-fKlmpciK3c9U/edit",
    brand: "QuickLending",
  },
  {
    id: 5,
    name: "Prácticas y procedimientos clave",
    type: "document",
    url: "https://docs.google.com/document/d/1la6WUhGwXjdc1GbKMIt3fMXr4dnBqtK2/edit",
    brand: "QuickLending",
  },
  {
    id: 6,
    name: "Políticas y protocolos",
    type: "document",
    url: "https://docs.google.com/document/d/1tUjCrfWfSP3O0zLJivxPKHKwv2XzTiSu/edit",
    brand: "QuickLending",
  },
  {
    id: 7,
    name: "Reglamento interno REVIEW",
    type: "document",
    url: "https://docs.google.com/document/d/182vRM-YzL5Y0YTkmVJ39BxUs7QwHeBJr/edit",
    brand: "QuickLending",
  },
  {
    id: 8,
    name: "Introducción al rol del empleado",
    type: "document",
    url: "https://docs.google.com/document/d/1jbspO7lyrz9z2wgSB6OfzYMfhYNyTtwM/edit",
    brand: "QuickLending",
  },
];

export default libraryData;
