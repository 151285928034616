import React, { useState } from "react";
import especificaData from "./especificaData";
import Modal from "./modal";
import "./especifica.css";

const Especifica = () => {
  const [expandedCategory, setExpandedCategory] = useState(null); // Categoría expandida
  const [expandedRole, setExpandedRole] = useState(null); // Rol expandido
  const [selectedFile, setSelectedFile] = useState(null); // Archivo seleccionado

  // Manejar expansión de categorías
  const toggleCategory = (category) => {
    setExpandedCategory(expandedCategory === category ? null : category);
    setExpandedRole(null); // Cierra cualquier rol expandido al cambiar de categoría
  };

  // Manejar expansión de roles
  const toggleRole = (role) => {
    setExpandedRole(expandedRole === role ? null : role);
  };

  // Abrir archivo en el modal
  const openFile = (file) => {
    setSelectedFile(file);
  };

  // Cerrar el modal
  const closeModal = () => {
    setSelectedFile(null);
  };

  // Obtener emoji según el tipo de archivo
  const getEmojiForType = (type) => {
    switch (type) {
      case "document":
        return "📄";
      case "pdf":
        return "📕";
      case "video":
        return "📹";
      case "photo":
        return "📸";
      case "youtube":
        return "🎥";
      default:
        return "📁";
    }
  };

  return (
    <div className="especifica-container">
      <div className="dropdowns-container">
        {especificaData.map((category, index) => (
          <div key={index} className="dropdown">
            {/* Categoría */}
            <button
              className="dropdown-btn"
              onClick={() => toggleCategory(category.category)}
            >
              {category.category}{" "}
              {expandedCategory === category.category ? "▲" : "▼"}
            </button>

            {/* Roles */}
            {expandedCategory === category.category && (
              <div className="dropdown-content">
                {category.roles.map((role, roleIndex) => (
                  <div
                    key={roleIndex}
                    className="nested-dropdown role-container"
                  >
                    <button
                      className="dropdown-btn role-btn"
                      onClick={() => toggleRole(role.role)}
                    >
                      {role.role} {expandedRole === role.role ? "▲" : "▼"}
                    </button>

                    {/* Detalles del rol */}
                    {expandedRole === role.role && (
                      <div className="dropdown-details">
                        {role.details.map((detail) => (
                          <div
                            key={detail.id}
                            className="dropdown-item"
                            onClick={() => openFile(detail)}
                          >
                            <span className="item-name">{detail.name}</span>
                            <span>{getEmojiForType(detail.type)}</span>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>

      {/* Modal para abrir archivos */}
      {selectedFile && (
        <Modal onClose={closeModal}>
          {selectedFile.type === "pdf" ? (
            <div className="modal-content">
              <h3>{selectedFile.name}</h3>
              <iframe
                src={selectedFile.url}
                title={selectedFile.name}
                width="100%"
                height="500px"
                style={{ border: "none" }}
              ></iframe>
            </div>
          ) : selectedFile.type === "video" ? (
            <video controls src={selectedFile.url} className="modal-content" />
          ) : (
            <p>Unsupported file type</p>
          )}
        </Modal>
      )}
    </div>
  );
};

export default Especifica;
