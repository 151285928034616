/// libraryData.js

import infoBg from "../../assets/img/112092.jpg";
import infoPDF from "../../assets/img/QLGLOSSARY2024.pdf";

const libraryData = [
  {
    id: 1,
    name: "ABC PROCESSING",
    type: "document",
    url: "https://docs.google.com/document/d/16ZKWVDlfPHLjTTlm_k2ghAPooYGCoaezyU3bjTT690E/edit?usp=sharing",
  },
  {
    id: 16,
    name: "ABC Account Manager",
    type: "document",
    url: "https://docs.google.com/document/d/1S6rNFmVRMbFzfy-42w1Pl8blFovfVt5pSPKBrlUu2r0/edit?usp=sharing",
  },
  {
    id: 2,
    name: "How Hard Money Loans Work in 2024! (Easy Beginners Guide To Hard Money Loans)",
    type: "youtube",
    url: "https://www.youtube.com/embed/5Upqy2zcqlA?si=Yn-sZP5vtokJPo6H",
  },
  {
    id: 3,
    name: "QL GLOSSARY 2024",
    type: "pdf",
    url: infoPDF,
  },
  { id: 4, name: "Foto de ejemplo", type: "photo", url: infoBg },
  {
    id: 5,
    name: "The Pro’s and Con’s of the SBA 7a Loan",
    type: "youtube",
    url: "https://www.youtube.com/embed/iKHEYvE7iBM?si=jeLgiAMS6GZnxcNi",
  },
  {
    id: 6,
    name: "What is a Term Loan? | DFI30 Explainer",
    type: "youtube",
    url: "https://www.youtube.com/embed/5HHkdmfF0r8?si=sm7C7tAV1JFuEgZ-",
  },
  {
    id: 7,
    name: "Loan to Value Ratio “LTV” Explained",
    type: "youtube",
    url: "https://www.youtube.com/embed/N2I11H2SlAQ?si=oUGHhdj4LkqMV9WU",
  },
  {
    id: 8,
    name: "Explained by a 20 year old: Down Payment | Koukun",
    type: "youtube",
    url: "https://www.youtube.com/embed/-mOf_nNBlWQ?si=yoRspWq3cDZCJ_Z8",
  },
  {
    id: 9,
    name: "Excel Calculate Interest Only Monthly Mortgage Payment",
    type: "youtube",
    url: "https://www.youtube.com/embed/p4qIm0ROqD0?si=h5b2_HEFBl_lIz0L",
  },
  {
    id: 10,
    name: "How to Calculate an Interest Only Mortgage",
    type: "youtube",
    url: "https://www.youtube.com/embed/12IO3-cc7Ys?si=KOiQCA0aJzi_Tsi1",
  },
  {
    id: 11,
    name: "Fixed vs ARM Mortgage: How Do They Compare? | NerdWallet",
    type: "youtube",
    url: "https://www.youtube.com/embed/OwU24LYKYEo?si=UEptq24qY6klYTny",
  },
  {
    id: 12,
    name: "Variable vs Fixed Interest Rate",
    type: "youtube",
    url: "https://www.youtube.com/embed/wkscvZNauJU?si=bixYeoV0Qnwv2nRi",
  },
  {
    id: 13,
    name: "Variable vs Fixed Interest Rate",
    type: "youtube",
    url: "https://www.youtube.com/embed/wkscvZNauJU?si=bixYeoV0Qnwv2nRi",
  },
  {
    id: 14,
    name: "Variable vs Fixed Interest Rate",
    type: "youtube",
    url: "https://www.youtube.com/embed/wkscvZNauJU?si=bixYeoV0Qnwv2nRi",
  },
  {
    id: 15,
    name: "Variable vs Fixed Interest Rate",
    type: "youtube",
    url: "https://www.youtube.com/embed/wkscvZNauJU?si=bixYeoV0Qnwv2nRi",
  },
];

export default libraryData;
