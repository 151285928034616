// TrainingBanner.js
import React from "react";
import styles from "./trainingBanner.module.css";

const TrainingBanner = ({ backgroundImage, title }) => {
  return (
    <div
      className={styles.banner}
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <div className={styles.overlay}></div>
      <h1 className={styles.title}>{title}</h1>
    </div>
  );
};

export default TrainingBanner;
