import React, { useState } from "react";
import { FaGlobe, FaBuilding, FaBook } from "react-icons/fa"; // Importa iconos
import styles from "./trainingSidebar.module.css";
import { MdOutlineQuiz } from "react-icons/md";

const TrainingSidebar = ({ setSelectedModule, startTour }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      {/* Botón para abrir/cerrar la barra lateral */}
      <button className={styles.toggler} onClick={toggleSidebar}>
        ☰
      </button>

      {/* Barra lateral para pantallas grandes */}
      <div
        className={`${styles.sidebar} ${isOpen ? styles.open : ""}`}
        id="ssidebar"
      >
        <nav className={styles.nav}>
          {/* Botón para iniciar el tour */}
          <button className={styles.tourButton} onClick={startTour}>
            <span className={styles.iconGuide}>
              <p className={styles.pGuide}>?</p>
            </span>
            <span className={styles.borderGuide}></span>
          </button>
          <ul>
            <li onClick={() => setSelectedModule("informacion-global")}>
              <span>Who We Are </span>
              <FaGlobe />
            </li>
            <li onClick={() => setSelectedModule("area-especifica")}>
              <span>Career Descriptions </span>
              <FaBuilding />
            </li>
            <li onClick={() => setSelectedModule("biblioteca")}>
              <span>Information Library </span>
              <FaBook />
            </li>
            <li onClick={() => setSelectedModule("test")}>
              <span>Knowledge Test </span>
              <MdOutlineQuiz />
            </li>
          </ul>
        </nav>
      </div>

      {/* Barra de iconos para dispositivos móviles */}
      <div className={styles.mobileNavbar}>
        <div onClick={() => setSelectedModule("informacion-global")}>
          <FaGlobe />
          <span>Global</span>
        </div>
        <div onClick={() => setSelectedModule("area-especifica")}>
          <FaBuilding />
          <span>Area</span>
        </div>
        <div onClick={() => setSelectedModule("biblioteca")}>
          <FaBook />
          <span>Library</span>
        </div>
        <div onClick={() => setSelectedModule("test")}>
          <MdOutlineQuiz />
          <span>Test </span>
        </div>
      </div>

      {/* Fondo oscurecido al abrir la barra lateral en móviles */}
      {isOpen && <div className={styles.overlay} onClick={toggleSidebar}></div>}
    </div>
  );
};

export default TrainingSidebar;
