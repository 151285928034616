import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { signOut } from "firebase/auth";
import { auth, db, storage } from "../../firebase"; // Make sure to import storage
import { useAuthState } from "react-firebase-hooks/auth";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { toast } from "react-hot-toast";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import { Navbar, Nav, Button, Modal, Form } from "react-bootstrap";
import styles from "./Header.module.css";
import Joyride from "react-joyride";

const Header = () => {
  const navigate = useNavigate();
  const [user] = useAuthState(auth);
  const [showEditModal, setShowEditModal] = useState(false);
  const [updatedUser, setUpdatedUser] = useState({
    displayName: "",
    email: "",
    position: "",
    country: "",
    birthday: "",
    about: "",
    photoURL: "", // Add field for photo URL
  });
  const [newPhoto, setNewPhoto] = useState(null);

  const [run, setRun] = useState(false);
  const steps = [
    {
      target: "#navbar",
      content: "Este es el menú principal para navegar.",
    },
    {
      target: ".userListContainer",
      content: "Aquí encontrarás la información de contacto.",
    },
  ];

  const startTour = () => {
    setRun((prevRun) => !prevRun);
  };

  useEffect(() => {
    if (user) {
      const fetchUserData = async () => {
        try {
          const userDoc = await getDoc(doc(db, "users", user.uid));
          if (userDoc.exists()) {
            setUpdatedUser(userDoc.data());
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
          toast.error("Error fetching user data");
        }
      };

      fetchUserData();
    }
  }, [user]);

  const handleTraining = async () => {
    try {
      navigate("/trainings");
      toast.success("Successfully Training!");
    } catch (error) {
      console.error("Error signing out:", error);
      toast.error("This didn't work.");
    }
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate("/login");
      toast.success("Successfully logged out!");
    } catch (error) {
      console.error("Error signing out:", error);
      toast.error("This didn't work.");
    }
  };

  const handleShowEditModal = () => setShowEditModal(true);
  const handleCloseEditModal = () => setShowEditModal(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUpdatedUser({ ...updatedUser, [name]: value });
  };

  const handlePhotoChange = (e) => {
    if (e.target.files[0]) {
      setNewPhoto(e.target.files[0]);
    }
  };

  const handleUpdateUser = async () => {
    if (!user) return;

    try {
      let photoURL = updatedUser.photoURL;

      if (newPhoto) {
        const photoRef = ref(storage, `profilePhotos/${user.uid}`);
        await uploadBytes(photoRef, newPhoto);
        photoURL = await getDownloadURL(photoRef);
      }

      const userDoc = doc(db, "users", user.uid);
      await updateDoc(userDoc, { ...updatedUser, photoURL });
      toast.success("Profile updated successfully!");
      handleCloseEditModal();
    } catch (error) {
      console.error("Error updating user:", error);
      toast.error("Failed to update profile.");
    }
  };

  return (
    <header className={styles.header}>
      <Navbar expand="lg" className={styles.container} id="navbar">
        <Link to="/users" className={styles.logo}>
          <img
            className={styles.logo_blanco}
            src="/img/logo-blanco-AI.png"
            alt="Atlas Investments"
          />
        </Link>
        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          className="navbar-toggler"
          style={{
            backgroundSize: "contain",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23FFFFFF' viewBox='0 0 30 30'%3E%3Cpath stroke='rgba(255, 255, 255, 1)' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E")`,
          }}
        />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            {user && (
              <>
                <div className={styles.navList}>
                  <Nav.Item>
                    <Button
                      onClick={handleTraining}
                      className={styles.editButton}
                    >
                      Trainings <i className="bi bi-book"></i>
                    </Button>
                  </Nav.Item>
                  <Nav.Item>
                    <Button
                      onClick={handleShowEditModal}
                      className={styles.editButton}
                    >
                      Edit Profile <i className="bi bi-pencil"></i>
                    </Button>
                  </Nav.Item>
                  <Nav.Item>
                    <Button
                      onClick={handleLogout}
                      className={styles.logoutButton}
                    >
                      Logout <i className="bi bi-box-arrow-right"></i>
                    </Button>
                  </Nav.Item>
                  {/* Botón para iniciar el tour */}
                  <Nav.Item>
                    <Button
                      onClick={startTour}
                      className={styles.tourButton} // Agrega una clase específica para estilos
                    >
                      <i className="bi bi-question-circle"></i>
                    </Button>
                    <Joyride
                      steps={steps}
                      run={run}
                      continuous
                      showProgress
                      showSkipButton
                      styles={{
                        options: {
                          zIndex: 1005,
                        },
                      }}
                    />
                  </Nav.Item>
                </div>
              </>
            )}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      {user && (
        <Modal show={showEditModal} onHide={handleCloseEditModal}>
          <Modal.Header closeButton>
            <Modal.Title>Edit Profile</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="formDisplayName">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  name="displayName"
                  value={updatedUser.displayName}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group controlId="formEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  value={updatedUser.email}
                  onChange={handleInputChange}
                  disabled
                />
              </Form.Group>
              <Form.Group controlId="formPosition">
                <Form.Label>Position</Form.Label>
                <Form.Control
                  type="text"
                  name="position"
                  value={updatedUser.position}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group controlId="formCountry">
                <Form.Label>Country</Form.Label>
                <Form.Control
                  type="text"
                  name="country"
                  value={updatedUser.country}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group controlId="formBirthday">
                <Form.Label>Birthday</Form.Label>
                <Form.Control
                  type="date"
                  name="birthday"
                  value={updatedUser.birthday}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group controlId="formAbout">
                <Form.Label>About Me</Form.Label>
                <Form.Control
                  as="textarea"
                  name="about"
                  value={updatedUser.about}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group controlId="formPhoto">
                <Form.Label>Profile Photo</Form.Label>
                <Form.Control
                  type="file"
                  name="photo"
                  onChange={handlePhotoChange}
                />
                {updatedUser.photoURL && (
                  <img
                    src={updatedUser.photoURL}
                    alt="Profile"
                    className={styles.profilePhotoPreview}
                  />
                )}
              </Form.Group>
              <Button variant="primary" onClick={handleUpdateUser}>
                Save Changes
              </Button>
            </Form>
          </Modal.Body>
        </Modal>
      )}
    </header>
  );
};

export default Header;
